<template>
  <div class="flex container mine">
    <aside>
      <!-- 侧边栏内容 -->
    </aside>
    <div class="components">
      <div class="news-detail">
        <h2 class="news-title">{{ news.title }}</h2>
        <div class="news-meta">{{ news.created_at }}</div>
        <div class="news-content" v-html="news.content"></div>
      </div>
    </div>
  </div>
</template>
 <script>
import avatar from '@/assets/image/avatar.png';
import { getInfo } from "@/api/news/index";

 export default {
  data () {
    return {
      news: {
      }
    };
  },
  mounted () {
    this.getInfo();

  },

  methods: {
    
    getInfo(){
      let id = this.$route.query.id;
      getInfo({id:id}).then(res => {
          console.log(res);
        if(res.code==200){
          this.news = res.data;
        }
      })
      },

  }
}
</script>
 <style lang='scss' scoped>
.mine{
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
   .components{
    flex: 1;
    margin-left: 16px;
    border-radius: 4px;
    // background: #fff;
  }
   .news-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    .news-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
      text-align: center; /* 将标题居中对齐 */
    }
    .news-meta {
      color: #999;
      margin-bottom: 16px;
      text-align: center; /* 将日期居中对齐 */
    }
     .news-content {
      line-height: 1.8;
      font-size: 16px;
      color: #333;
       p {
        margin-bottom: 16px;
      }
       a {
        color: #007bff;
        text-decoration: none;
         &:hover {
          text-decoration: underline;
        }
      }
       strong {
        font-weight: bold;
      }
    }
  }
}
</style>