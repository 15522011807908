import request from '@/api/request'

//删除好友
export function postGroup(data) {
    return request({
        url: '/apiv2/director/postGroup',
        method: 'POST',
        data
    })
}

export function getInfo(data) {
    return request({
        url: '/api/news/getInfo',
        method: 'GET',
        params: data
    })
}
